<template>
  <b-form>

    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(consultResourcesElementName + '.section.patient_immune_status') }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="patient_hepatitis_vaccine_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.patient_hepatitis_vaccine')"
                :element-selection-list="booleanDnList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="patient_full_vaccine_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.patient_full_vaccine')"
                :element-selection-list="booleanDnList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="dose_quantity_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.dose_quantity')"
                :element-selection-list="doseQuantityList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="applied_scheme_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.applied_scheme')"
                :element-selection-list="appliedSchemeList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="4"
              md="4"
            >
              <date-picker
                :element-required="getDateRequired()"
                element-field="last_dose_date"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.last_dose_date')"
                :disabled="disabled"
              />
            </b-col>

            <b-col
              cols="4"
              md="4"
            >
              <b-form-group label-for="element-status">
                <b-form-checkbox
                  v-model="elementData.last_dose_date_none"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  inline
                  value="1"
                  unchecked-value="0"
                  :disabled="disabled"
                />
                <span class="font-weight-bold">{{ $t(consultResourcesElementName + '.object.none') }}</span>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="serological_control_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.serological_control')"
                :element-selection-list="booleanDnnaList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="serological_level_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.serological_level')"
                :element-selection-list="booleanDnnauList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="other_background_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.other_background')"
                :element-selection-list="otherBackgroundList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row v-if="elementData.patient.sex.value === 'Mujer'">
            <b-col
              cols="4"
              md="4"
            >
              <date-picker
                :element-required="!disabled"
                element-field="fum_date"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.fum_date')"
                :disabled="disabled"
              />
            </b-col>
            <b-col
              cols="7"
              xl="7"
            >
              <b-form-group
                label-for="planification_method"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.planification_method') }}</span><span v-if="!disabled" class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.planification_method')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-input
                    id="planification_method"
                    v-model="elementData.planification_method"
                    maxlength="250"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BFormCheckbox, BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import RadioSelectPicker from '@/views/core/RadioSelectPicker.vue'

export default {
  components: {
    RadioSelectPicker, BFormCheckbox, DatePicker, BCard, BRow, BCol, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    booleanDnnaList: {
      type: Array,
      required: true,
    },
    booleanDnList: {
      type: Array,
      required: true,
    },
    booleanDnnauList: {
      type: Array,
      required: true,
    },
    doseQuantityList: {
      type: Array,
      required: true,
    },
    appliedSchemeList: {
      type: Array,
      required: true,
    },
    otherBackgroundList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      resourcesElementName: 'case',
      consultResourcesElementName: 'consult',
    }
  },
  mounted() {
  },
  methods: {
    getDateRequired() {
      return !this.elementData || this.elementData.last_dose_date_none === '0'
    },
  },
}
</script>
