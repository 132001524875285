<template>
  <div>
    <b-form @submit.prevent="save">
      <validation-observer
        ref="elementForm"
        #default="{ invalid }">
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="d-none d-sm-inline"
                >{{ $t(consultResourcesElementName + '.section.base_info') }}:
                {{ elementData.consult_type }}</span
              >
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="4"
                md="4"
                class="mr-1 ml-1">
                <b-row>
                  <b-form-group label-for="consult_type">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(consultResourcesElementName + '.object.consult_type')
                    }}</span
                    ><span
                      v-if="!disabled"
                      class="text-danger"
                      >*</span
                    >
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(consultResourcesElementName + '.object.consult_type')
                      "
                      :rules="disabled ? '' : 'required'">
                      <div class="v-select-wrapper">
                        <v-select
                          v-model="elementData.consult_type_id"
                          :options="consultTypeList"
                          label="value"
                          value="id"
                          :reduce="(option) => option.id"
                          :placeholder="$t('list.defaultOption')"
                          :filter-by="filterWithoutAccents"
                          :disabled="disabled" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-row>
                <b-row>
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.consult_date')
                  }}</span>
                  {{ formatDate(elementData.final_consult_date) }}
                </b-row>
                <b-row v-if="false">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.consult_date')
                  }}</span>
                  {{ formatDate(elementData.consult_date) }}
                </b-row>
              </b-col>
              <b-col
                cols="4"
                md="4"
                class="mr-1 ml-1">
                <b-row>
                  <b-form-group label-for="consult_status">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(consultResourcesElementName + '.object.consult_status')
                    }}</span
                    ><span
                      v-if="!disabled"
                      class="text-danger"
                      >*</span
                    >
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(
                          consultResourcesElementName + '.object.consult_status'
                        )
                      "
                      :rules="disabled ? '' : 'required'">
                      <div class="v-select-wrapper">
                        <v-select
                          v-model="elementData.consult_status_id"
                          :options="consultStatusList"
                          label="value"
                          value="id"
                          :reduce="(option) => option.id"
                          :placeholder="$t('list.defaultOption')"
                          :filter-by="filterWithoutAccents"
                          :disabled="disabled"
                          @input="onConsultStatusChange" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-form-group label-for="consult_status">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(consultResourcesElementName + '.object.case_type')
                    }}</span
                    ><span
                      v-if="!disabled"
                      class="text-danger"
                      >*</span
                    >
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(consultResourcesElementName + '.object.case_type')
                      "
                      :rules="disabled ? '' : 'required'">
                      <div class="v-select-wrapper">
                        <v-select
                          v-model="elementData.case_type_id"
                          :options="consultCaseTypeList"
                          label="value"
                          value="id"
                          :reduce="(option) => option.id"
                          :placeholder="$t('list.defaultOption')"
                          :filter-by="filterWithoutAccents"
                          :disabled="disabled"
                          @input="onCaseTypeChange" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-row>
              </b-col>
              <b-col
                id="caseDateFields"
                class="mr-1 ml-1">
                <b-row>
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.start_date')
                  }}</span>
                  {{ formatDate(caseDate.start_date) }}
                </b-row>
                <b-row>
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.end_date')
                  }}</span>
                  {{ formatDate(caseDate.end_date) }}
                </b-row>
                <b-row>
                  <b-button
                    variant="primary"
                    :disabled="disabled"
                    @click="reschedule()">
                    <span class="text-nowrap">{{
                      $t(consultResourcesElementName + '.object.reschedule')
                    }}</span>
                  </b-button>
                </b-row>
              </b-col>
              <b-col
                v-if="
                  elementData.is_last_page && currentConsultStatus == 'ATTENDED'
                "
                class="mr-1 ml-1">
                <b-row>
                  <div
                    v-if="
                      hasRoles([
                        'CONSULTATION_COORDINATOR',
                        'SUPER_ADMINISTRATOR',
                      ])
                    "
                    class="d-inline p-1">
                    <b-button
                      variant="primary"
                      @click="assign_edition()">
                      <span class="text-nowrap">{{
                        $t('actions.let_edit')
                      }}</span>
                    </b-button>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <div id="identification_fields">
          <b-card>
            <b-card-header>
              <div class="text-primary h2">
                <feather-icon
                  icon="StarIcon"
                  size="25"
                  class="mr-0 mr-sm-50"
                  variant="primary" />
                <span class="d-none d-sm-inline">{{
                  $t(patientResourcesElementName + '.section.patient_info')
                }}</span>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <!-- Right Col: Table -->
                <b-col
                  cols="4"
                  md="4">
                  <b-form-group label-for="patient_document_type">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(patientResourcesElementName + '.object.documentType')
                    }}</span
                    ><span
                      v-if="!disabled"
                      class="text-danger"
                      >*</span
                    >
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(patientResourcesElementName + '.object.documentType')
                      "
                      :rules="disabled ? '' : 'required'">
                      <div class="v-select-wrapper">
                        <v-select
                          v-model="elementData.patient.document_type_id"
                          :options="documentTypeList"
                          label="value"
                          value="id"
                          :reduce="(option) => option.id"
                          :placeholder="$t('list.defaultOption')"
                          :filter-by="filterWithoutAccents"
                          :disabled="disabled" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                  xl="4">
                  <b-form-group label-for="identity">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(patientResourcesElementName + '.object.identity')
                    }}</span
                    ><span
                      v-if="!disabled"
                      class="text-danger"
                      >*</span
                    >
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(patientResourcesElementName + '.object.identity')
                      "
                      :rules="disabled ? '' : 'required'">
                      <b-form-input
                        id="identity"
                        v-model="elementData.patient.identity"
                        maxlength="20"
                        :disabled="disabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="2"
                  xl="2">
                  <b-media>
                    <b-col
                      cols="12"
                      md="12"
                      class="text-center mb-1">
                      <b-img
                        :src="localAvatar"
                        text=""
                        variant="light-success"
                        class="border"
                        fluid />
                    </b-col>
                    <div class="mb-1">
                      <div class="mb-1">
                        <b-button
                          variant="primary"
                          @click="$refs.refInputAvatar.click()">
                          <input
                            ref="refInputAvatar"
                            type="file"
                            class="d-none"
                            @change="handleAvatarImage()" />
                          <span class="d-none d-sm-inline">{{
                            $t(
                              patientResourcesElementName +
                                '.object.updateAvatar'
                            )
                          }}</span>
                          <feather-icon
                            icon="EditIcon"
                            class="d-inline d-sm-none" />
                        </b-button>
                      </div>
                      <div class="mb-1">
                        <b-button
                          variant="danger"
                          @click="removeAvatarImage()">
                          <span class="d-none d-sm-inline">{{
                            $t(
                              patientResourcesElementName +
                                '.object.deleteAvatar'
                            )
                          }}</span>
                          <feather-icon
                            icon="TrashIcon"
                            class="d-inline d-sm-none" />
                        </b-button>
                      </div>
                    </div>
                  </b-media>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <b-form-group label-for="first_name">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(patientResourcesElementName + '.object.first_name')
                    }}</span
                    ><span
                      v-if="!disabled"
                      class="text-danger"
                      >*</span
                    >
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(patientResourcesElementName + '.object.first_name')
                      "
                      :rules="disabled ? '' : 'required'">
                      <b-form-input
                        id="first_name"
                        v-model="elementData.patient.first_name"
                        maxlength="60"
                        :disabled="disabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Right Col: Table -->
                <b-col cols="3">
                  <b-form-group label-for="second_name">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(patientResourcesElementName + '.object.second_name')
                    }}</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(patientResourcesElementName + '.object.second_name')
                      ">
                      <b-form-input
                        id="second_name"
                        v-model="elementData.patient.second_name"
                        maxlength="60"
                        :disabled="disabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group label-for="first_surname">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(patientResourcesElementName + '.object.first_surname')
                    }}</span
                    ><span
                      v-if="!disabled"
                      class="text-danger"
                      >*</span
                    >
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(
                          patientResourcesElementName + '.object.first_surname'
                        )
                      "
                      :rules="disabled ? '' : 'required'">
                      <b-form-input
                        id="first_surname"
                        v-model="elementData.patient.first_surname"
                        maxlength="60"
                        :disabled="disabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Right Col: Table -->
                <b-col cols="3">
                  <b-form-group label-for="second_surname">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(patientResourcesElementName + '.object.second_surname')
                    }}</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(
                          patientResourcesElementName + '.object.second_surname'
                        )
                      ">
                      <b-form-input
                        id="second_surname"
                        v-model="elementData.patient.second_surname"
                        maxlength="60"
                        :disabled="disabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="d-flex">
                <b-col
                  cols="12"
                  md="12">
                  <template v-if="elementData.patient">
                    <contacts-edit-tab
                      :new-element="false"
                      :element-data="elementData.patient"
                      :contact-type-list="contactTypeList"
                      :department-list="contactDepartmentList"
                      :city-list="contactCityList"
                      :relationship-list="relationshipList"
                      :population-zone-list="populationZoneList"
                      :is-patient="true"
                      :disabled="disabled"
                      class="mt-2 pt-75" />
                  </template>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="3"
                  xl="3">
                  <date-picker
                    :element-required="!disabled"
                    element-field="birthdate"
                    :root-element-data="elementData.patient"
                    :element-label="
                      $t(patientResourcesElementName + '.object.birthdate')
                    "
                    :disabled="disabled" />
                </b-col>
                <b-col
                  cols="3"
                  xl="3">
                  <b-form-group label-for="age">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(patientResourcesElementName + '.object.age')
                    }}</span>
                    <b-form-input
                      id="age"
                      v-model="age"
                      maxlength="3"
                      :disabled="true" />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="9"
                  md="9">
                  <b-form-group label-for="element-status">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(
                        patientResourcesElementName + '.object.covid_vaccinator'
                      )
                    }}</span>
                    <b-form-checkbox
                      v-model="elementData.patient.covid_vaccinator"
                      class="mr-0 mt-50"
                      name="is-rtl"
                      switch
                      value="1"
                      unchecked-value="0"
                      :disabled="disabled" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12">
                  <radio-select-picker
                    :element-required="!disabled"
                    element-field="patient_type_id"
                    :root-element-data="elementData.patient"
                    :element-label="
                      $t(patientResourcesElementName + '.object.patient_type')
                    "
                    :element-selection-list="patientTypeList"
                    :inline="false"
                    :disabled="disabled" />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12">
                  <radio-select-picker
                    :element-required="!disabled"
                    element-field="civil_status_id"
                    :root-element-data="elementData.patient"
                    :element-label="
                      $t(patientResourcesElementName + '.object.civil_status')
                    "
                    :element-selection-list="patientCivilStatusList"
                    :inline="false"
                    :disabled="disabled" />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12">
                  <radio-select-picker
                    :element-required="!disabled"
                    element-field="scholarship_id"
                    :root-element-data="elementData.patient"
                    :element-label="
                      $t(patientResourcesElementName + '.object.scholarship')
                    "
                    :element-selection-list="patientScholarshipList"
                    :inline="false"
                    :disabled="disabled" />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card>
            <b-card-header>
              <div class="text-primary h2">
                <feather-icon
                  icon="StarIcon"
                  size="25"
                  class="mr-0 mr-sm-50"
                  variant="primary" />
                <span class="d-none d-sm-inline">{{
                  $t(
                    consultResourcesElementName +
                      '.section.patient_company_information'
                  )
                }}</span>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  cols="4"
                  md="4">
                  <b-form-group label-for="name">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(companyResourcesElementName + '.object.name')
                    }}</span
                    ><span
                      v-if="!disabled"
                      class="text-danger"
                      >*</span
                    >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t(companyResourcesElementName + '.object.name')"
                      :rules="disabled ? '' : 'required'">
                      <b-form-input
                        id="name"
                        v-model="elementData.company.name"
                        maxlength="120"
                        :disabled="disabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="4"
                  md="4">
                  <b-form-group label-for="headquarter">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(consultResourcesElementName + '.object.headquarter')
                    }}</span
                    ><span
                      v-if="!disabled"
                      class="text-danger"
                      >*</span
                    >

                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(consultResourcesElementName + '.object.headquarter')
                      "
                      :rules="disabled ? '' : 'required'">
                      <b-form-input
                        id="headquarter"
                        v-model="elementData.accident_place"
                        :disabled="disabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="4"
                  md="4">
                  <b-form-group label-for="department">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(companyResourcesElementName + '.object.department')
                    }}</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(companyResourcesElementName + '.object.department')
                      ">
                      <div class="v-select-wrapper">
                        <v-select
                          v-model="elementData.company.department_id"
                          :options="companyDepartmentList"
                          label="value"
                          value="id"
                          :reduce="(option) => option.id"
                          :placeholder="$t('list.defaultOption')"
                          :filter-by="filterWithoutAccents"
                          :disabled="disabled"
                          @input="onCompanyDepartmentChange" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                  md="4">
                  <b-form-group label-for="city">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(companyResourcesElementName + '.object.city')
                    }}</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t(companyResourcesElementName + '.object.city')">
                      <div class="v-select-wrapper">
                        <v-select
                          v-model="elementData.company.city_id"
                          :options="companyCityList"
                          label="value"
                          value="id"
                          :reduce="(option) => option.id"
                          :placeholder="$t('list.defaultOption')"
                          :filter-by="filterWithoutAccents"
                          :disabled="disabled" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="2">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold info_title">{{
                    $t(
                      consultResourcesElementName + '.object.patient_seniority'
                    )
                  }}</span
                  ><span
                    v-if="!disabled"
                    class="text-danger"
                    >*</span
                  >
                </b-col>
                <b-col cols="2">
                  <b-form-group label-for="patient_seniority_years">
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(
                          consultResourcesElementName +
                            '.object.patient_seniority_years'
                        )
                      "
                      :rules="disabled ? '' : 'required'">
                      <b-form-input
                        id="patient_seniority_years"
                        v-model="elementData.patient.seniority_years"
                        :placeholder="
                          $t(
                            consultResourcesElementName +
                              '.object.patient_seniority_years_ph'
                          )
                        "
                        type="number"
                        :formatter="yyFormat"
                        :disabled="disabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group label-for="patient_seniority_months">
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(
                          consultResourcesElementName +
                            '.object.patient_seniority_months'
                        )
                      "
                      :rules="disabled ? '' : 'required'">
                      <b-form-input
                        id="patient_seniority_months"
                        v-model="elementData.patient.seniority_months"
                        :placeholder="
                          $t(
                            consultResourcesElementName +
                              '.object.patient_seniority_months_ph'
                          )
                        "
                        type="number"
                        :formatter="mmFormat"
                        :disabled="disabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group label-for="patient_seniority_days">
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(
                          consultResourcesElementName +
                            '.object.patient_seniority_days'
                        )
                      "
                      :rules="disabled ? '' : 'required'">
                      <b-form-input
                        id="patient_seniority_days"
                        v-model="elementData.patient.seniority_days"
                        :placeholder="
                          $t(
                            consultResourcesElementName +
                              '.object.patient_seniority_days_ph'
                          )
                        "
                        type="number"
                        :formatter="ddFormat"
                        :disabled="disabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card>
            <b-card-header>
              <div class="text-primary h2">
                <feather-icon
                  icon="StarIcon"
                  size="25"
                  class="mr-0 mr-sm-50"
                  variant="primary" />
                <span class="d-none d-sm-inline">{{
                  $t(
                    consultResourcesElementName +
                      '.section.accident_information'
                  )
                }}</span>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  cols="12"
                  md="12">
                  <b-form-group label-for="accident_description">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold info_title">{{
                      $t(
                        consultResourcesElementName +
                          '.object.accident_description'
                      )
                    }}</span
                    ><span
                      v-if="!disabled"
                      class="text-danger"
                      >*</span
                    >
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(
                          consultResourcesElementName +
                            '.object.accident_description'
                        )
                      "
                      :rules="disabled ? '' : 'required'">
                      <b-form-textarea
                        id="accident_description"
                        v-model="elementData.accident_description"
                        maxlength="6000"
                        max="6000"
                        :disabled="disabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </validation-observer>
    </b-form>

    <div v-if="!disabled">
      <b-modal
        id="modal-date"
        size="xl"
        no-close-on-esc
        no-close-on-backdrop
        no-modal-header-close
        hide-footer
        :title="$t(stepResourcesElementName + '.object.edit_calendar_title')">
        <template #modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <b-button
            v-b-modal.close-confirmation
            size="sm"
            variant="outline-danger">
            Cancelar
          </b-button>
          <h5>
            {{ $t(stepResourcesElementName + '.object.edit_calendar_title') }}
          </h5>
        </template>
        <template>
          <calendar-edit-tab
            :element-data="caseDate"
            class="mt-2 pt-75" />
        </template>
      </b-modal>
      <b-modal
        id="close-confirmation"
        size="sm"
        title="Confirmación"
        hide-footer>
        <template>
          <p class="my-1">Si cierra el diálogo perderá los cambios</p>
          <p>¿Desea continuar?</p>
          <b-button
            size="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="danger"
            @click="reset_changes()">
            Aceptar
          </b-button>
          <b-button
            size="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="primary"
            @click="close_confirmation()">
            Cancelar
          </b-button>
        </template>
      </b-modal>
    </div>
    <div>
      <b-modal
        :id="'modal-assign-edition-user-' + caseDateUuid"
        size="xl"
        hide-footer
        title="Permiso de edición">
        <template #modal-header="{ close }">
          <h5>Permiso de Edición</h5>
        </template>
        <template>
          <assign-edition-tab
            :element-data="elementData"
            :case-date-uuid="caseDateUuid"
            :doctor-list="doctorList"
            :edition-time="editionTime"
            class="mt-2 pt-75" />
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BModal,
  BFormTextarea,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BImg,
  BMedia,
} from 'bootstrap-vue'
import { required } from '@validations'
import ContactsEditTab from '@/views/core/contact/EditContacts.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import DatePicker from '@/views/core/DatePicker.vue'
import RadioSelectPicker from '@/views/core/RadioSelectPicker.vue'
import { isNumeric } from 'echarts/lib/util/number'
import CalendarEditTab from '@/views/schedules/calendar-consult/index.vue'
import AssignEditionTab from '@/views/meet/local/edition/AssignEdition.vue'
import { hasRole } from '@/auth/utils'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import filtersMixin from '@/libs/filtersMixin'

export default {
  components: {
    AssignEditionTab,
    BModal,
    CalendarEditTab,
    BFormTextarea,
    RadioSelectPicker,
    BFormCheckbox,
    DatePicker,
    ContactsEditTab,
    BImg,
    BMedia,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [filtersMixin],
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    caseDate: {
      type: Object,
      required: true,
    },
    doctorList: {
      type: Array,
      required: true,
    },
    editionTime: {
      type: Object,
      required: true,
    },
    documentTypeList: {
      type: Array,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
    contactDepartmentList: {
      type: Array,
      required: true,
    },
    contactCityList: {
      type: Array,
      required: true,
    },
    companyDepartmentList: {
      type: Array,
      required: true,
    },
    companyCityList: {
      type: Array,
      required: true,
    },
    consultTypeList: {
      type: Array,
      required: true,
    },
    consultStatusList: {
      type: Array,
      required: true,
    },
    consultCaseTypeList: {
      type: Array,
      required: true,
    },
    patientScholarshipList: {
      type: Array,
      required: true,
    },
    patientCivilStatusList: {
      type: Array,
      required: true,
    },
    patientTypeList: {
      type: Array,
      required: true,
    },
    relationshipList: {
      type: Array,
      required: true,
    },
    populationZoneList: {
      type: Array,
      required: true,
    },
    currentConsultStatus: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      patientResourcesElementName: 'patient',
      companyResourcesElementName: 'enterprise',
      stepResourcesElementName: 'step',
      required,
      firstCharge: true,
      temporalElement: {},
      localAvatar: undefined,
      caseDateUuid: '',
    }
  },
  computed: {
    age(val) {
      if (
        this.elementData &&
        this.elementData.patient &&
        this.elementData.patient.birthdate
      ) {
        let date = this.elementData.patient.birthdate
        if (!moment.isMoment(date)) {
          date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
        }
        return moment().diff(date, 'years')
      }
      return null
    },
  },
  watch: {
    'elementData.patient.seniority_years': function (newYears) {
      if (
        isNumeric(newYears) &&
        this.isNotNumeric(this.elementData.patient.seniority_months) &&
        this.isNotNumeric(this.elementData.patient.seniority_days)
      ) {
        this.elementData.patient.seniority_months = '00'
        this.elementData.patient.seniority_days = '00'
      }
    },
    'elementData.patient.seniority_months': function (newMonths) {
      if (
        isNumeric(newMonths) &&
        this.isNotNumeric(this.elementData.patient.seniority_years) &&
        this.isNotNumeric(this.elementData.patient.seniority_days)
      ) {
        this.elementData.patient.seniority_years = '00'
        this.elementData.patient.seniority_days = '00'
      }
    },
    'elementData.patient.seniority_days': function (newDays) {
      if (
        isNumeric(newDays) &&
        this.isNotNumeric(this.elementData.patient.seniority_years) &&
        this.isNotNumeric(this.elementData.patient.seniority_months)
      ) {
        this.elementData.patient.seniority_years = '00'
        this.elementData.patient.seniority_months = '00'
      }
    },
  },
  mounted() {
    this.$root.$emit(
      'fetch-company-cities',
      this.elementData.company.department_id
    )
    this.localAvatar = this.elementData.patient.avatar
    if (
      this.elementData.patient.seniority_years !== undefined &&
      this.elementData.patient.seniority_years != null
    ) {
      this.elementData.patient.seniority_years = this.yyFormat(
        this.elementData.patient.seniority_years
      )
    }
    if (
      this.elementData.patient.seniority_months !== undefined &&
      this.elementData.patient.seniority_months != null
    ) {
      this.elementData.patient.seniority_months = this.mmFormat(
        this.elementData.patient.seniority_months
      )
    }
    if (
      this.elementData.patient.seniority_days !== undefined &&
      this.elementData.patient.seniority_days != null
    ) {
      this.elementData.patient.seniority_days = this.ddFormat(
        this.elementData.patient.seniority_days
      )
    }
    this.onConsultStatusChange()
    this.caseDateUuid = this.caseDate.meet.uuid
  },
  methods: {
    hasRoles(roleKeys) {
      for (let i = 0; i < roleKeys.length; i++) {
        if (hasRole(roleKeys[i])) {
          return true
        }
      }
      return false
    },
    isNotNumeric(value) {
      return value === null || isNaN(value)
    },
    handleAvatarImage() {
      this.createAvatarBase64mage(this.$refs.refInputAvatar.files[0])
      this.elementData.patient.avatarUpdated = true
    },
    createAvatarBase64mage(Avatar) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.elementData.patient.avatar = e.target.result
        this.localAvatar = this.elementData.patient.avatar
      }
      reader.readAsDataURL(Avatar)
    },
    removeAvatarImage() {
      this.elementData.patient.avatarUpdated = true
      this.elementData.patient.avatar = ''
      this.elementData.patient.avatar_id = null
      this.$refs.refInputAvatar.value = ''
      this.localAvatar = this.elementData.patient.avatar
    },
    close_confirmation() {
      this.$root.$emit('bv::hide::modal', 'close-confirmation')
    },
    reset_changes() {
      Object.assign(this.caseDate, this.temporalElement)
      this.$root.$emit('bv::hide::modal', 'modal-date')
      this.$root.$emit('bv::hide::modal', 'close-confirmation')
    },
    reschedule() {
      Object.assign(this.temporalElement, this.caseDate)
      this.$root.$emit('bv::show::modal', 'modal-date')
    },
    assign_edition() {
      this.$root.$emit('bv::show::modal', `modal-assign-edition-user-${this.caseDateUuid}`)
    },
    formatDate(element) {
      let ret = moment(element, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm')
      if (ret == 'Invalid date' || ret == 'Fecha inválida') {
        ret = moment(element).format('DD/MM/YYYY HH:mm')
      }
      return ret == 'Invalid date' || ret == 'Fecha inválida' ? '' : ret
    },
    numberFormat(value) {
      const ret = parseInt(value).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
      return ret > 99 ? 99 : ret
    },
    yyFormat(value) {
      const ret = parseInt(value).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
      return ret > 50 ? 50 : ret
    },
    mmFormat(value) {
      const ret = parseInt(value).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
      return ret > 12 ? 12 : ret
    },
    ddFormat(value) {
      const ret = parseInt(value).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
      return ret > 31 ? 31 : ret
    },
    onCompanyDepartmentChange() {
      this.elementData.company.city_id = null
      this.$root.$emit(
        'fetch-company-cities',
        this.elementData.company.department_id
      )
    },
    update_visibility() {
      const element = document.getElementById('identification_fields')
      const consultDateFields = document.getElementById('caseDateFields')
      if (this.firstCharge && !this.elementData.consult_status_id) {
        this.elementData.consult_status_id = this.caseDate.consult_status_id
        this.firstCharge = false
      }
      let status_key = ''
      if (this.elementData.consult_status_id) {
        status_key = this.consultStatusList.find(
          (element) => this.elementData.consult_status_id === element.id
        ).key
      }
      if (this.elementData.consult_status_id && status_key === 'ATTENDED') {
        element.classList.remove('hidden-div')
      } else {
        element.classList.add('hidden-div')
      }
      if (status_key === 'RESCHEDULE') {
        consultDateFields.classList.remove('hidden-div')
      } else {
        consultDateFields.classList.add('hidden-div')
      }
    },
    onConsultStatusChange() {
      this.update_visibility()
      this.onCaseTypeChange()
    },
    onCaseTypeChange() {
      let not_attended = false
      const status = this.consultStatusList.find(
        (element) => element.id == this.elementData.consult_status_id
      )
      if (!status || status.key !== 'ATTENDED') {
        not_attended = true
      }
      this.$root.$emit(
        'change-case-type',
        this.elementData.case_type_id,
        not_attended
      )
    },
    save() {
      this.$refs.elementForm.validate().then((success) => {
        if (success) {
          this.$parent.save(this.elementData.patient)
        }
      })
    },
  },
}
</script>

<style>
.hidden-div {
  display: none;
}
.no-interactive-tooltip .tooltip {
  pointer-events: none; /* Desactiva la interactividad */
}
</style>
