<template>
  <b-form
    @submit.prevent="save"
  >
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(consultResourcesElementName + '.section.accident_characteristics') }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="4"
              md="4"
            >
              <date-time-picker
                :element-required="!disabled"
                element-field="accident_date"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.accident_date')"
                :disabled="disabled"
              />
            </b-col>
            <b-col
              cols="8"
              md="8"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="work_shift_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.work_shift')"
                :element-selection-list="workShiftList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              md="6"
            >
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.accident_hour') }}</span>
              {{ accident_hour }}
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.accident_day_of_week') }}</span>
              {{ accident_day_of_week }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="3"
              md="3"
            >
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.elapsed_hours') }}</span>
            </b-col>
            <b-col
              cols="1"
              md="1"
            >

              <b-form-input
                id="elapsed_hours"
                v-model="elementData.elapsed_hours"
                :placeholder="$t(consultResourcesElementName + '.object.elapsed_hours')"
                type="number"
                :disabled="disabled"
              />
            </b-col>
            <b-col
              cols="2"
              md="2"
            />
            <b-col
              cols="6"
              md="6"
            >
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.elapsed_time') }}</span>
              {{ elapsed_time }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="job_held_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.job_held')"
                :element-selection-list="jobHeldList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="3"
              xl="3"
            >
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.job_seniority') }}</span><span v-if="!disabled" class="text-danger">*</span>
            </b-col>
            <b-col
              cols="3"
              xl="3"
            >
              <b-form-group
                label-for="job_seniority_years"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.job_seniority_years')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-input
                    id="job_seniority_years"
                    v-model="elementData.job_seniority_years"
                    :placeholder="$t(consultResourcesElementName + '.object.job_seniority_years')"
                    type="number"
                    :formatter="numberFormat"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              xl="3"
            >
              <b-form-group
                label-for="job_seniority_months"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.job_seniority_months')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-input
                    id="job_seniority_months"
                    v-model="elementData.job_seniority_months"
                    :placeholder="$t(consultResourcesElementName + '.object.job_seniority_months')"
                    type="number"
                    :formatter="numberFormat"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              xl="3"
            >
              <b-form-group
                label-for="job_seniority_days"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.job_seniority_days')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-input
                    id="job_seniority_days"
                    v-model="elementData.job_seniority_days"
                    :placeholder="$t(consultResourcesElementName + '.object.job_seniority_days')"
                    type="number"
                    :formatter="numberFormat"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="9"
              xl="9"
            >
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.accident_quantity') }}</span><span v-if="!disabled" class="text-danger">*</span>
            </b-col>
            <b-col
              cols="3"
              xl="3"
            >
              <b-form-group
                label-for="accident_quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.accident_quantity')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-input
                    id="accident_quantity"
                    v-model="elementData.accident_quantity"
                    :placeholder="$t(consultResourcesElementName + '.object.accident_quantity_placeholder')"
                    type="number"
                    :formatter="numberFormatAccident"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="job_area_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.job_area')"
                :element-selection-list="jobAreaList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="accident_location_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.accident_place')"
                :element-selection-list="accidentPlaceList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="accident_type_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.accident_type')"
                :element-selection-list="accidentTypeList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="lesion_depth_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.lesion_depth')"
                :element-selection-list="lesionDepthList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="skin_condition_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.skin_condition')"
                :element-selection-list="skinConditionList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="blood_amount_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.blood_amount')"
                :element-selection-list="bloodAmountList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="body_part_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.body_part')"
                :element-selection-list="bodyPartList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="injury_agent_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.injury_agent')"
                :element-selection-list="injuryAgentList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="injury_time_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.injury_time')"
                :element-selection-list="injuryTimeList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="compromised_liquid_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.compromised_liquid')"
                :element-selection-list="compromisedLiquidList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="ecp_purpose_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.ecp_purpose')"
                :element-selection-list="ecpPurposeList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="was_his_works_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.was_his_works')"
                :element-selection-list="booleanNAList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-form>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import DateTimePicker from '@/views/core/DateTimePicker.vue'
import RadioSelectPicker from '@/views/core/RadioSelectPicker.vue'
import { isNumeric } from 'echarts/lib/util/number'

export default {
  components: {
    RadioSelectPicker, DateTimePicker, BCard, BRow, BCol, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    workShiftList: {
      type: Array,
      required: true,
    },
    jobHeldList: {
      type: Array,
      required: true,
    },
    jobAreaList: {
      type: Array,
      required: true,
    },
    accidentPlaceList: {
      type: Array,
      required: true,
    },
    accidentTypeList: {
      type: Array,
      required: true,
    },
    lesionDepthList: {
      type: Array,
      required: true,
    },
    skinConditionList: {
      type: Array,
      required: true,
    },
    bloodAmountList: {
      type: Array,
      required: true,
    },
    bodyPartList: {
      type: Array,
      required: true,
    },
    injuryAgentList: {
      type: Array,
      required: true,
    },
    injuryTimeList: {
      type: Array,
      required: true,
    },
    compromisedLiquidList: {
      type: Array,
      required: true,
    },
    ecpPurposeList: {
      type: Array,
      required: true,
    },
    booleanNAList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      patientResourcesElementName: 'patient',
      companyResourcesElementName: 'enterprise',
      required,
      logoUpdated: false,
      logo: null,
    }
  },
  computed: {
    accident_hour: {
      get() {
        if (this.elementData.accident_date) {
          console.log(this.elementData.accident_date)
          return moment(this.elementData.accident_date, 'DD/MM/YYYYTHH:mm A').format('hh:mm A')
        }
        return ''
      },
    },
    accident_day_of_week: {
      get() {
        if (this.elementData.accident_date) {
          const localLocale = moment(this.elementData.accident_date, 'DD/MM/YYYYTHH:mm')
          moment.locale('es')
          localLocale.locale(false)
          return localLocale.format('dddd')
        }
        return ''
      },
    },
    elapsed_hours: {
      get() {
        if (this.elementData.accident_date && this.elementData.consult_date) {
          const duration = moment.duration(moment(this.elementData.consult_date, 'DD/MM/YYYYTHH:mm').diff(moment(this.elementData.accident_date, 'DD/MM/YYYYTHH:mm A')))

          const hours = Math.floor(duration.asHours())
          const minutes = Math.floor(duration.asMinutes()) - (hours * 60)

          return `${hours}.${minutes}`
        }
        return ''
      },
    },
    elapsed_time: {
      get() {
        const formats = ['DD/MM/YYYY HH:mm', 'DD/MM/YYYY HH:mm A', 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'DD/MM/YYYY[T]HH:mm:ss']
        const consult_date = moment(this.elementData.consult_date, formats)
        const accident_date = moment(this.elementData.accident_date, formats)
        if (consult_date.isValid() && accident_date.isValid()) {
          const duration = moment.duration(consult_date.diff(accident_date))
          const hours = duration.asHours()
          if (hours < 24) {
            return '<24h (<1 día)'
          } if (hours < 48) {
            return '24-48 h (1-2 días)'
          } if (hours < 72) {
            return '48-72 h (2-3 días)'
          } if (hours < 96) {
            return '72-96 h (3-4 días)'
          }
          return '>96 h (>=5 días)'
        }
        return ''
      },
    },
  },
  watch: {
    'elementData.job_seniority_years': function (newYears) {
      if (isNumeric(newYears) && isNaN(this.elementData.job_seniority_months) && isNaN(this.elementData.job_seniority_days)) {
        this.elementData.job_seniority_months = 0
        this.elementData.job_seniority_days = 0
      }
    },
    'elementData.job_seniority_months': function (newMonths) {
      if (isNumeric(newMonths) && isNaN(this.elementData.job_seniority_years) && isNaN(this.elementData.job_seniority_days)) {
        this.elementData.job_seniority_years = 0
        this.elementData.job_seniority_days = 0
      }
    },
    'elementData.job_seniority_days': function (newDays) {
      if (isNumeric(newDays) && isNaN(this.elementData.job_seniority_years) && isNaN(this.elementData.job_seniority_months)) {
        this.elementData.job_seniority_years = 0
        this.elementData.job_seniority_months = 0
      }
    },
  },
  mounted() {
    console.log('accident_date', this.elementData.accident_date)
  },
  methods: {
    numberFormat(value) {
      const ret = parseInt(value).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
      return ret > 99 ? 99 : ret
    },
    numberFormatAccident(value) {
      const ret = parseInt(value).toLocaleString('en-US', {
        useGrouping: false,
      })
      return ret > 999 ? 999 : ret
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          if (this.logo && this.logoUpdated) {
            this.elementData.patient.logo = this.logo
          }
          this.$parent.save(this.elementData.patient)
        }
      })
    },
  },
}
</script>

<style>
.info_title {
    text-decoration: underline;
    font-weight: bolder !important;
}
</style>
