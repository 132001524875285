<template>
  <b-card>
    <b-card-body>

      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-start mb-1 mb-md-0"
        >
          <b-button
            variant="primary"
            @click="addMedicine"
          >
            <span class="text-nowrap">{{ $t(resourcesElementName + '.object.add') }}</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>

        <b-table
          ref="refMedicinesListTable"
          class="position-relative"
          :items="elementData.services"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t(resourcesElementName + '.search.not_found')"
        >
          <!-- Column: key -->
          <template #cell(status)="data">
            <h2 class="inline">
              <b-badge
                pill
                :variant="`light-${resolveStatusVariant(data.item.status)}`"
                class="text-capitalize mr-0 mt-50"
              >
                {{ resolveStatusMessage(data.item.status) }}
              </b-badge>
            </h2>
          </template>
          <!-- Column: Status -->
          <!-- Column: actions -->
          <template #cell(actions)="data">
            <b-col>
              <b-button
                v-if="data.item.status"
                :id="get_delete_button_id(data.item.id)"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                variant="primary"
                @click="deleteMedicine(data.item.id)"
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>

              <b-tooltip
                v-if="data.item.status"
                :target="get_delete_button_id(data.item.id)"
                triggers="hover"
              >
                <span class="align-middle ml-50">{{ $t('actions.delete') }}</span>
              </b-tooltip>
            </b-col>
          </template>

        </b-table>
      </b-row>
      <div>
        <b-modal
          id="modal-medicine"
          size="lg"
          hide-footer
          :title="$t(resourcesElementName + '.object.add')"
        >
          <template>
            <edit-medicine
              :element-data="medicineData"
              :medicines-list="medicinesList"
            />
          </template>
        </b-modal>
      </div>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BTooltip, BCard, BCardBody, BButton, BRow, BCol, BBadge, BTable, BModal,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EditMedicine from './EditMedicine.vue'

export default {
  components: {
    EditMedicine, BTooltip, BModal, BTable, BCard, BButton, BRow, BCol, BBadge, BCardBody,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    medicinesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [
      ],
      internal_medicines: [],
      resourcesElementName: 'services',
      required,
      medicineData: {},
      newMedicine: true,
    }
  },
  beforeDestroy() {
    this.$root.$off('save-medicine')
  },
  mounted() {
    this.tableColumns = [
      { key: 'cups_name', label: this.$t(`${this.resourcesElementName}.object.medicine_name`), sortable: true },
      { key: 'cups', label: this.$t(`${this.resourcesElementName}.object.cum_code`), sortable: true },
      { key: 'actions', label: this.$t(`${this.resourcesElementName}.object.actions`) },
    ]
    const _self = this
    this.$root.$on('save-medicine', medicineData => {
      const index = _self.elementData.services.findIndex(element => element.id === medicineData.id)

      if (index < 0) { /*  validating that the new element doesn't exists */
        let canAdd = true
        if (_self.elementData.services.length > 0) {
          const serv = _self.elementData.services.find(element => element.medicine_group.key === medicineData.medicine_group.key && element.medicine_group.key !== 'N/A')
          canAdd = !serv || medicineData.medicine_group.key === 'N/A'
        }
        if (canAdd) {
          _self.elementData.services.push(medicineData)
          console.log(_self.elementData.services)
          _self.$forceUpdate()
        } else {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t('consult.action_results.add_medicine.title'),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t('consult.action_results.add_medicine.failure'),
            },
          })
        }
      }
    })
  },
  methods: {
    get_delete_button_id(key) {
      return `delete_button${key}`
    },
    addMedicine() {
      this.medicineData = { id: Math.floor(Math.random() * 100) * -1, status: 1 }
      this.$root.$emit('bv::show::modal', 'modal-medicine')
    },
    deleteMedicine(id) {
      const element = this.elementData.services.find(element => element.id === id)
      let index = this.elementData.services.indexOf(element)
      if (index > -1) {
        this.elementData.services.splice(index, 1)
      }
      for (const order of this.elementData.orders) {
        index = order.services.findIndex(element => element.id == id)
        if (index > -1) {
          order.services.splice(index, 1)
        }
      }
    },
    resolveStatusVariant(status) {
      if (status > 0) return 'success'
      return 'warning'
    },
    resolveStatusMessage(status) {
      if (status > 0) return this.$t(`${this.resourcesElementName}.statusValue.active`)
      return this.$t(`${this.resourcesElementName}.statusValue.inactive`)
    },
  },
}
</script>

<style>

</style>
