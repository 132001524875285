<template>
  <b-form
    @submit.prevent="save"
  >
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(consultResourcesElementName + '.section.accident_characteristics') }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="4"
              md="4"
            >
              <date-picker
                :element-required="!disabled"
                element-field="accident_date"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.accident_date')"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="!disabled"
                element-field="job_held_id"
                :root-element-data="elementData"
                :element-label="$t(consultResourcesElementName + '.object.job_held')"
                :element-selection-list="jobHeldList"
                :inline="false"
                :disabled="disabled"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="consult_motivation"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.consult_motivation') }}</span><span v-if="!disabled" class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.consult_motivation')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-textarea
                    id="consult_motivation"
                    v-model="elementData.consult_motivation"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="current_disease"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.current_disease') }}</span><span v-if="!disabled" class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.current_disease')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-textarea
                    id="current_disease"
                    v-model="elementData.current_disease"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="background"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.background') }}</span><span v-if="!disabled" class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.background')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-textarea
                    id="background"
                    v-model="elementData.background"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="system_review"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.system_review') }}</span><span v-if="!disabled" class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.system_review')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-textarea
                    id="system_review"
                    v-model="elementData.system_review"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

    </validation-observer>
  </b-form>

</template>

<script>
import {
  BFormTextarea, BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import RadioSelectPicker from '@/views/core/RadioSelectPicker.vue'

export default {
  components: {
    BFormTextarea, RadioSelectPicker, BCard, BRow, BCol, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, DatePicker,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    jobHeldList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      patientResourcesElementName: 'patient',
      companyResourcesElementName: 'enterprise',
      required,
      logoUpdated: false,
      logo: null,
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style>
.info_title {
    text-decoration: underline;
    font-weight: bolder !important;
}
</style>
