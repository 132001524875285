<template>
  <b-form>

    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(consultResourcesElementName +
              '.section.source') }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="known_source"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.known_source') }}</span><span v-if="!disabled" class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.known_source')"
                  :rules="disabled ? '' : 'required'"
                >
                  <div class="v-select-wrapper">
                    <v-select
                      v-model="elementData.known_source_id"
                      :options="booleanNAList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="$t('list.defaultOption')"
                      :filter-by="filterWithoutAccents"
                      :disabled="disabled"
                      @input="change_known_source"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div v-if="show_observations">
            <b-row>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group label-for="source_document_type">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName +
                    '.object.source_document_type') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.source_document_type')"
                  >
                    <div class="v-select-wrapper">
                      <v-select
                        v-model="elementData.source_document_type_id"
                        :options="documentTypeList"
                        label="value"
                        value="id"
                        :reduce="option => option.id"
                        :placeholder="$t('list.defaultOption')"
                        :filter-by="filterWithoutAccents"
                        :disabled="disabled"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Right Col: Table -->
              <b-col
                cols="4"
                xl="4"
              >
                <b-form-group label-for="source_identity">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName +
                    '.object.source_identity') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.source_identity')"
                  >
                    <b-form-input
                      id="name"
                      v-model="elementData.source_identity"
                      maxlength="20"
                      :disabled="disabled"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="3"
                xl="3"
              >
                <b-form-group label-for="source_first_name">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName +
                    '.object.source_first_name') }}</span>
                  <b-form-input
                    id="name"
                    v-model="elementData.source_first_name"
                    maxlength="20"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <!-- Right Col: Table -->
              <b-col
                cols="3"
                xl="3"
              >
                <b-form-group label-for="source_second_name">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName +
                    '.object.source_second_name') }}</span>
                  <b-form-input
                    id="name"
                    v-model="elementData.source_second_name"
                    maxlength="20"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <!-- Right Col: Table -->
              <b-col
                cols="3"
                xl="3"
              >
                <b-form-group label-for="source_first_surname">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName +
                    '.object.source_first_surname') }}</span>
                  <b-form-input
                    id="name"
                    v-model="elementData.source_first_surname"
                    maxlength="20"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <!-- Right Col: Table -->
              <b-col
                cols="3"
                xl="3"
              >
                <b-form-group label-for="source_second_surname">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName +
                    '.object.source_second_surname') }}</span>
                  <b-form-input
                    id="name"
                    v-model="elementData.source_second_surname"
                    maxlength="20"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="3"
                xl="3"
              >
                <date-picker
                  :element-required="false"
                  element-field="source_birthdate"
                  :root-element-data="elementData"
                  :element-label="$t(resourcesElementName + '.object.source_birthdate')"
                  :disabled="disabled"
                />
              </b-col>
              <b-col
                cols="3"
                xl="3"
              >
                <b-form-group
                  label-for="source_age"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.source_age') }}</span>
                  <b-form-input
                    id="source_age"
                    v-model="source_age"
                    maxlength="3"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="3"
                xl="3"
              >
                <b-form-group
                  label-for="source_phone"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.source_phone') }}</span>
                  <b-form-input
                    id="source_phone"
                    v-model="elementData.source_phone"
                    maxlength="20"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.source_positive') }}</span>
            </b-row>
            <b-row>
              <b-col
                cols="2"
                md="2"
              >
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.hiv') }}</span>
                  <b-form-checkbox
                    v-model="elementData.source_positive_hiv"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                md="2"
              >
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.vhc') }}</span>
                  <b-form-checkbox
                    v-model="elementData.source_positive_vhc"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                md="2"
              >
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.vhb') }}</span>
                  <b-form-checkbox
                    v-model="elementData.source_positive_vhb"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                md="2"
              >
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.syphilis') }}</span>
                  <b-form-checkbox
                    v-model="elementData.source_positive_syphilis"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    :disabled="disabled"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label-for="source_observations"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.source_observations') }}</span><span v-if="!disabled" class="text-danger">*</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(consultResourcesElementName + '.object.source_observations')"
                    :rules="disabled ? '' : 'required'"
                  >
                    <b-form-textarea
                      id="observations"
                      v-model="elementData.source_observations"
                      maxlength="6000"
                      max="6000"
                      :disabled="disabled"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-row v-if="!show_observations">

            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="source_observations"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.source_observations') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.source_observations')"
                >
                  <b-form-textarea
                    id="observations"
                    v-model="elementData.source_observations"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              xl="12"
            >
              <Lab-tests-list
                :case_id="elementData.id"
                :title="$t('labtests.section.source')"
                type="SOURCE"
              />

            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.source_exams_ok') }}</span>
            </b-col>
            <b-col cols="4">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.source_exams_ok_id"
                  :options="booleanList"
                  label="value"
                  value="id"
                  :reduce="option => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled"
                />
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BFormTextarea, BFormCheckbox, BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import moment from 'moment'
import vSelect from 'vue-select'
import LabTestsList from './labtest/list.vue'
import '@core/scss/vue/libs/vue-select.scss'
import filtersMixin from '@/libs/filtersMixin'

export default {
  components: {
    DatePicker,
    LabTestsList,
    BFormTextarea,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [filtersMixin],
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    documentTypeList: {
      type: Array,
      required: true,
    },
    booleanNAList: {
      type: Array,
      required: true,
    },
    booleanList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      resourcesElementName: 'case',
      consultResourcesElementName: 'consult',
      source_age: null,
      show_observations: false,
    }
  },
  watch: {
    'elementData.source_birthdate': function (val) {
      if (val) {
        this.calculate_age(val)
      }
    },
    'elementData.source_exams_ok_id': function (val) {
      this.elementData.source_exams_ok = this.booleanList.find(element => element.id == val)
    },
  },
  mounted() {
    this.change_known_source()
    this.calculate_age(this.elementData.source_birthdate)
  },
  methods: {
    change_known_source() {
      this.show_observations = this.booleanNAList.find(element => this.elementData.known_source_id === element.id).key === 'YES'
    },
    calculate_age(birthdate) {
      if (birthdate) {
        let date = birthdate
        console.log('birthdate', birthdate)
        if (!moment.isMoment(date)) {
          date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
        }
        this.source_age = moment().diff(date, 'years')
      }
    },
  },
}
</script>
