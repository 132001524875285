var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Orden médica de consulta")])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"consultType"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Próxima consulta")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"consultType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.internalTrackingType,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},on:{"input":_vm.onNextConsultChange},model:{value:(_vm.consultData.nextConsultId),callback:function ($$v) {_vm.$set(_vm.consultData, "nextConsultId", $$v)},expression:"consultData.nextConsultId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"serviceType"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Servicio")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"serviceType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.medicalConsultServicesList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.consultData.service.service_id),callback:function ($$v) {_vm.$set(_vm.consultData.service, "service_id", $$v)},expression:"consultData.service.service_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('date-picker',{attrs:{"element-required":_vm.requiresFollow,"element-field":"proposed_date","root-element-data":_vm.consultData,"element-label":_vm.$t(_vm.consultResourcesElementName + '.object.proposed_date')}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"observations"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v("Observaciones")]),_c('validation-provider',{attrs:{"name":"observations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"observations","maxlength":"500"},model:{value:(_vm.consultData.observations),callback:function ($$v) {_vm.$set(_vm.consultData, "observations", $$v)},expression:"consultData.observations"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"id":"cancel-button","variant":"primary"},on:{"click":function($event){return _vm.hide_modal()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.back'))+" ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('actions.save'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }