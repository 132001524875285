<template>
  <div>
    <b-row class="m-1">
      <b-col
        cols="12"
        class="d-flex">
        <h2 class="mb-1 text-primary">- {{ title }}</h2>
      </b-col>
    </b-row>

    <b-table
      ref="refElementsListTable"
      class="position-relative"
      :items="items"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
      <!-- Column: key -->
      <template #cell(generic_name)="data">
        {{ data.item.generic_name }}
      </template>
      <template #cell(laboratory)="data">
        {{ data.item.laboratory }}
      </template>
      <template #cell(description)="data">
        {{ data.item.description }}
      </template>
      <template #cell(date)="data">
        {{ formatDate(data.item.date) }}
      </template>
      <template #cell(result)="data">
        {{ data.item.result ? data.item.result.value : '' }}
      </template>
      <template #cell(provider)="data">
        {{ data.item.provider ? data.item.provider.name : '' }}
      </template>
      <template #cell(made_by_avenir)="data">
        <span v-if="data.item.made_by_avenir"> {{ $t('boolean.yes') }}</span>
        <span v-if="data.item.made_by_avenir == false">
          {{ $t('boolean.no') }}</span
        >
      </template>
      <template #cell(report)="data">
        <div v-if="data.item.report">
          <a
            id="view-report-button"
            class="btn mb-1 mb-sm-0 mr-0 mr-sm-1 btn-secondary"
            :href="getUrl(data.item.report)"
            target="_blank">
            {{ $t(RESOURCES_ELEMENT_NAME + '.download') }}
          </a>
          <b-tooltip
            target="view-report-button"
            triggers="hover">
            <span class="align-middle ml-50">{{
              $t(RESOURCES_ELEMENT_NAME + '.download')
            }}</span>
          </b-tooltip>
          <!-- ? REPORT -->
          <b-tooltip
            target="view-report-button"
            :title="$t(RESOURCES_ELEMENT_NAME + '.download')"
            triggers="hover"
            :noninteractive="true"
            placement="topleft">
          </b-tooltip>
        </div>
      </template>
    </b-table>
    <!-- Pagination -->
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="total"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BTable, BPagination, BTooltip } from 'bootstrap-vue'
import store from '@/store'
import ElementStoreModule from '@/views/case/labtests/storeModule'
import moment from 'moment'

export default {
  components: {
    BTooltip,
    BRow,
    BCol,
    BTable,
    BPagination,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    case_id: {
      type: Number,
      requiered: true,
    },
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      tableColumns: [],
    }
  },
  watch: {
    perPage(val) {
      this.fetchElements(this.type)
    },
    currentPage(val) {
      this.fetchElements(this.type)
    },
    query(val) {
      this.currentPage = 1
      this.fetchElements(this.type)
    },
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'labtests'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-labtest-${this.RESOURCES_ELEMENT_NAME}`

    this.tableColumns = [
      {
        key: 'name',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.name`),
        sortable: true,
      },
      {
        key: 'date',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.date`),
        sortable: true,
      },
      {
        key: 'result',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.result`),
        sortable: true,
      },
      {
        key: 'provider',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.provider`),
        sortable: true,
      },
      {
        key: 'made_by_avenir',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.made_by_avenir`),
        sortable: true,
      },
      {
        key: 'report',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.report`),
        sortable: true,
      },
    ]
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )

    this.fetchElements(this.type)
    this.fetch_labs()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    getUrl(item) {
      if (!item || !item.path) {
        return ''
      }
      let path = item.path.split('/')
      path = path[path.length - 1]
      return `${process.env.VUE_APP_BASE_API}/api/v1/digital_file/digital_file/record/${path}`
    },
    formatDate(element) {
      const dateMoment = moment(element, 'DD/MM/YYYY')
      if (dateMoment.isValid()) {
        return dateMoment.format('DD/MM/YYYY')
      }
      return ''
    },
    async fetch_labs() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchLabs`, {})
        .then((response) => {
          const list = response.data.items
          list.unshift({
            id: null,
            name: this.$t(
              `${this.RESOURCES_ELEMENT_NAME}.object.select_lab_default`
            ),
          })
          return list
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined
          }
        })
    },
    fetchElements(type) {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_lab_tests`, {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            type,
            case: this.case_id,
          },
        })
        .then((response) => {
          console.info('response.data.items:', response.data)

          this.items = response.data.items
          this.total = response.data.total

          if (this.items && this.items.length > 0) {
          }
        })
        .catch((error) => {
          console.info(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
