<template>
  <b-form>

    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(consultResourcesElementName + '.section.diagnosis') }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="analysis"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.analysis') }}</span><span v-if="!disabled" class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.analysis')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-textarea
                    id="analysis"
                    v-model="elementData.analysis"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="plan"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.plan') }}</span><span v-if="!disabled" class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.plan')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-textarea
                    id="plan"
                    v-model="elementData.plan"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="origin_classification"
              >
                <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.origin_classification') }}</span>
                <div class="v-select-wrapper">
                  <v-select
                    v-model="elementData.origin_classification_id"
                    :options="originClassificationList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    :filter-by="filterWithoutAccents"
                    :disabled="disabled"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="plan"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.diagnosis') }}</span><span v-if="!disabled" class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.diagnosis')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-textarea
                    id="diagnosis"
                    v-model="elementData.diagnosis"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.main_diagnosis') }}</span>
            </b-col>
            <b-col cols="4">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.main_diagnosis_id"
                  :options="diagnosisList"
                  label="key"
                  value="id"
                  :reduce="option => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled"
                />
              </div>
            </b-col>
            <b-col cols="4">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.main_diagnosis_id"
                  :options="diagnosisList"
                  label="value"
                  value="id"
                  :reduce="option => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.secondary_diagnosis') }}</span>
            </b-col>
            <b-col cols="4">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.secondary_diagnosis_id"
                  :options="diagnosisList"
                  label="key"
                  value="id"
                  :reduce="option => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled"
                />
              </div>
            </b-col>
            <b-col cols="4">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.secondary_diagnosis_id"
                  :options="diagnosisList"
                  label="value"
                  value="id"
                  :reduce="option => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.consult_contact_type') }}</span><span v-if="!disabled" class="text-danger">*</span>
              <validation-provider
                #default="{ errors }"
                :name="$t(consultResourcesElementName + '.object.consult_contact_type')"
                :rules="disabled ? '' : 'required'"
              >
                <div class="v-select-wrapper">
                  <v-select
                    v-model="elementData.consult_contact_type_id"
                    :options="contactTypeList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    :filter-by="filterWithoutAccents"
                    :disabled="disabled"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.contact_diagnosis') }}</span><span v-if="!disabled" class="text-danger">*</span>
              <validation-provider
                #default="{ errors }"
                :name="$t(consultResourcesElementName + '.object.contact_diagnosis')"
                :rules="disabled ? '' : 'required'"
              >
                <div class="v-select-wrapper">
                  <v-select
                    v-model="elementData.contact_diagnosis_id"
                    :options="contactDiagnosisList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    :filter-by="filterWithoutAccents"
                    :disabled="disabled"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.patient_status') }}</span><span v-if="!disabled" class="text-danger">*</span>
              <validation-provider
                #default="{ errors }"
                :name="$t(consultResourcesElementName + '.object.patient_status')"
                :rules="disabled ? '' : 'required'"
              >
                <div class="v-select-wrapper">
                  <v-select
                    v-model="elementData.patient_status_id"
                    :options="patientStatusList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    :filter-by="filterWithoutAccents"
                    :disabled="disabled"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.case_status') }}</span><span v-if="!disabled" class="text-danger">*</span>
              <validation-provider
                #default="{ errors }"
                :name="$t(consultResourcesElementName + '.object.case_status')"
                :rules="disabled ? '' : 'required'"
              >
                <div class="v-select-wrapper">
                  <v-select
                    v-model="elementData.case_status_id"
                    :options="caseStatusList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    :filter-by="filterWithoutAccents"
                    :disabled="disabled"
                    @input="onCaseStatusChange"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="py-1">
            <b-col cols="6">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.requieres_follow') }}</span>
              <validation-provider
                #default="{ errors }"
                :name="$t(consultResourcesElementName + '.object.requieres_follow')"
              >
                <b-form-checkbox
                  v-model="elementData.requires_follow"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  switch
                  inline
                  :value="true"
                  :unchecked-value="false"
                  :disabled="disabled"
                  @change="onMedicalFollowChange()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              cols="6"
            >
              <date-picker
                v-if="elementData.requires_follow"
                :disabled="disabled"
                :element-label="$t(consultResourcesElementName + '.object.follow_schedule')"
                :element-required="elementData.requires_follow"
                :root-element-data="elementData"
                element-field="follow_schedule"
                :min-current-date="disabled ? null : elementData.requires_follow"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="observations"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(consultResourcesElementName + '.object.observations') }}</span><span v-if="!disabled" class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(consultResourcesElementName + '.object.observations')"
                  :rules="disabled ? '' : 'required'"
                >
                  <b-form-textarea
                    id="observations"
                    v-model="elementData.observations"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-form>
</template>
<script>
import {
  BFormTextarea, BFormCheckbox, BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import filtersMixin from '@/libs/filtersMixin'

export default {
  components: {
    BFormTextarea, BFormCheckbox, DatePicker, BCard, BRow, BCol, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, vSelect,
  },
  mixins: [filtersMixin],
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    diagnosisList: {
      type: Array,
      required: true,
    },
    originClassificationList: {
      type: Array,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
    contactDiagnosisList: {
      type: Array,
      required: true,
    },
    patientStatusList: {
      type: Array,
      required: true,
    },
    caseStatusList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
    }
  },
  watch: {
  },
  mounted() {
    this.elementData.case_status_id = this.caseStatusList.find(element => element.id == this.elementData.case_status_id) || null
  },
  methods: {
    onCaseStatusChange() {
      const { key } = this.caseStatusList.find(element => element.id == this.elementData.case_status_id)
      this.elementData.consult_case_status_key = key
    },
  },
}
</script>
<style>
.left_title {
    text-decoration: underline;
    font-weight: bolder !important;
}

.hidden-div{
    display: none;
  }
</style>
