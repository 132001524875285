<template>
  <b-form v-if="elementData.main_diagnosis_id !== undefined">
    <validation-observer
      ref="elementForm"
      #default="{ invalid }">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">{{
              $t(consultResourcesElementName + '.section.diagnosis')
            }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="4">
              <feather-icon
                icon="StarIcon"
                class="mr-75" />
              <span class="font-weight-bold info_title">{{
                $t(consultResourcesElementName + '.object.main_diagnosis')
              }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.main_diagnosis_id"
                  :options="diagnosisList"
                  label="key"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled" />
              </div>
            </b-col>
            <b-col cols="8">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.main_diagnosis_id"
                  :options="diagnosisList"
                  label="value"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled" />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                :root-element-data="elementData"
                element-field="principal_diagnosis_type_id"
                :element-label="
                  $t(
                    consultResourcesElementName +
                      '.object.principal_diagnosis_type'
                  )
                "
                :element-selection-list="principalDiagnosisTypeList"
                :inline="false"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <feather-icon
                icon="StarIcon"
                class="mr-75" />
              <span class="font-weight-bold info_title">{{
                $t(consultResourcesElementName + '.object.secondary_diagnosis')
              }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.secondary_diagnosis_id"
                  :options="diagnosisList"
                  label="key"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled" />
              </div>
            </b-col>
            <b-col cols="8">
              <div class="v-select-wrapper">
                <v-select
                  v-model="elementData.secondary_diagnosis_id"
                  :options="diagnosisList"
                  label="value"
                  value="id"
                  :reduce="(option) => option.id"
                  :placeholder="$t('list.defaultOption')"
                  :filter-by="filterWithoutAccents"
                  :disabled="disabled" />
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">{{
              $t(consultResourcesElementName + '.section.recommendations')
            }}</span>
            <span
              v-if="!disabled"
              class="text-danger"
              >*</span
            >
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <b-form-group label-for="recommendations">
                <validation-provider
                  #default="{ errors }"
                  :name="
                    $t(consultResourcesElementName + '.object.recommendations')
                  "
                  :rules="disabled ? '' : 'required'">
                  <b-form-textarea
                    id="recommendations"
                    v-model="elementData.recommendations"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">{{
              $t(consultResourcesElementName + '.section.observations')
            }}</span>
            <span
              v-if="!disabled"
              class="text-danger"
              >*</span
            >
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <span class="font-weight-bold info_title">{{
                $t(consultResourcesElementName + '.object.status')
              }}</span>
              {{ elementData.consult_case_status_value }}
            </b-col>
          </b-row>

          <div id="source_fields">
            <b-row>
              <b-col
                cols="3"
                md="3">
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.close_hiv')
                  }}</span>
                  <b-form-checkbox
                    v-model="elementData.close_hiv"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    disabled />
                </b-form-group>
              </b-col>
              <b-col
                cols="3"
                md="3">
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.close_vhc')
                  }}</span>
                  <b-form-checkbox
                    v-model="elementData.close_vhc"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    disabled />
                </b-form-group>
              </b-col>
              <b-col
                cols="3"
                md="3">
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.close_vhb')
                  }}</span>
                  <b-form-checkbox
                    v-model="elementData.close_vhb"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    disabled />
                </b-form-group>
              </b-col>
              <b-col
                cols="3"
                md="3">
                <b-form-group label-for="element-status">
                  <span class="font-weight-bold info_title">{{
                    $t(consultResourcesElementName + '.object.close_syphilis')
                  }}</span>
                  <b-form-checkbox
                    v-model="elementData.close_syphilis"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    disabled />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="severity_id"
                :root-element-data="elementData"
                :element-label="
                  $t(consultResourcesElementName + '.object.biological_danger')
                "
                :element-selection-list="severityList"
                :inline="false"
                :disabled="true" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <radio-select-picker
                :element-required="!disabled"
                element-field="consult_antibodies_status_id"
                :root-element-data="elementData"
                :element-label="
                  $t(
                    consultResourcesElementName +
                      '.object.consult_antibodies_status'
                  )
                "
                :element-selection-list="antobodiesStatusList"
                :inline="false"
                :exec="update_visibility"
                :disabled="disabled" />
            </b-col>
          </b-row>
          <div
            id="antibodies_size_fields"
            ref="antibodies_size_fields"
            class="hidden-div mb-2">
            <b-row>
              <b-col
                cols="6"
                md="6">
                <span class="font-weight-bold info_title">{{
                  $t(
                    consultResourcesElementName +
                      '.object.antibody_status_gt_ten'
                  )
                }}</span>
                <b-form-checkbox
                  v-model="elementData.antibody_status_gt_ten"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  inline
                  value="1"
                  unchecked-value="0"
                  :disabled="disabled" 
                  @change="handleChange('gt_ten')"
                  />
              </b-col>
              <b-col
                cols="6"
                md="6">
                <span class="font-weight-bold info_title">{{
                  $t(
                    consultResourcesElementName +
                      '.object.antibody_status_lt_ten'
                  )
                }}</span>
                <b-form-checkbox
                  v-model="elementData.antibody_status_lt_ten"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  inline
                  value="1"
                  unchecked-value="0"
                  :disabled="disabled" 
                  @change="handleChange('lt_ten')"
                  />
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <b-form-group label-for="recommendations_observations">
                <validation-provider
                  #default="{ errors }"
                  :name="
                    $t(
                      consultResourcesElementName +
                        '.object.recommendations_observations'
                    )
                  "
                  :rules="disabled ? '' : 'required'">
                  <b-form-textarea
                    id="observations"
                    v-model="elementData.recommendations_observations"
                    maxlength="6000"
                    max="6000"
                    :disabled="disabled" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-form>
</template>
<script>
import {
  BFormTextarea,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import RadioSelectPicker from '@/views/core/RadioSelectPicker.vue'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import filtersMixin from '@/libs/filtersMixin'

export default {
  components: {
    BFormTextarea,
    RadioSelectPicker,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [filtersMixin],
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    diagnosisList: {
      type: Array,
      required: true,
    },
    consultCaseStatusList: {
      type: Array,
      required: true,
    },
    severityList: {
      type: Array,
      required: true,
    },
    antobodiesStatusList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    principalDiagnosisTypeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      internalConsultCaseStatusList: [],
      resourcesElementName: 'case',
      consultResourcesElementName: 'consult',
    }
  },
  watch: {},
  mounted() {
    this.update_visibility()

    // Set diagnosis placeholder
    if (!this.elementData.main_diagnosis_id) {
      this.elementData.main_diagnosis_id = this.diagnosisList[0].id
    }
    if (!this.elementData.secondary_diagnosis_id) {
      this.elementData.secondary_diagnosis_id = this.diagnosisList[0].id
    }

    // Set case status options to closed and follow
    this.internalConsultCaseStatusList = this.consultCaseStatusList.filter(
      (element) => element.key === 'CLOSED' || element.key === 'FOLLOW'
    )
    this.elementData.consult_case_status_element =
      this.internalConsultCaseStatusList.find(
        (element) => element.key == this.elementData.consult_case_status_key
      )
    this.elementData.consult_case_status_id =
      this.elementData.consult_case_status_element.id
    this.elementData.consult_case_status_value =
      this.elementData.consult_case_status_element.value
  },
  methods: {
    update_visibility() {
      console.log('filtering', this.elementData.consult_antibodies_status_id)
      const element = document.getElementById('antibodies_size_fields')

      if (
        this.elementData.consult_antibodies_status_id &&
        this.antobodiesStatusList.find(
          (element) =>
            this.elementData.consult_antibodies_status_id === element.id
        ).key === 'KNOWN'
      ) {
        element.classList.remove('hidden-div')
      } else {
        element.classList.add('hidden-div')
      }
    },
    handleChange(selected) {
      if (selected === 'gt_ten' && this.elementData.antibody_status_gt_ten === '1') {
        this.elementData.antibody_status_lt_ten = '0';
      } else if (selected === 'lt_ten' && this.elementData.antibody_status_lt_ten === '1') {
        this.elementData.antibody_status_gt_ten = '0';
      }
    }
  },
}
</script>
<style>
.left_title {
  text-decoration: underline;
  font-weight: bolder !important;
}

.hidden-div {
  display: none;
}
</style>
