<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="d-none d-sm-inline">{{$t(resourcesElementName + '.section.medicines')}}</span>
      </div>
    </b-card-header>
    <b-card-body>
      <validation-observer 
        ref="elementForm"
        #default="{ invalid }"
        >
        <b-form
          @submit.prevent="save"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex justify-content-start mb-1 mb-md-0"
            >
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search.search')"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-table
              ref="refElementsListTable"
              class="position-relative"
              :items="medicinesList"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              :empty-text="$t(resourcesElementName + '.search.not_found')"
            >
              <!-- column: name -->
              <template #cell(actions)="data">
                <b-col>
                    <b-button 
                        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                        variant="primary" 
                        @click="select_medicine(data.item)" :id=get_select_button_id(data.item.id)>
                      <feather-icon icon="PlusIcon" />
                    </b-button>
        
                    <b-tooltip :target="get_select_button_id(data.item.id)" triggers="hover">
                      <span class="align-middle ml-50">{{$t(resourcesElementName +'.object.add_medicine')}}</span>
                    </b-tooltip>
                </b-col>
              </template>

            </b-table>
          </b-row>
          <b-row>
            <b-button 
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                variant="primary" 
                id="cancel-button" 
                @click="hide_modal()">
                {{$t('actions.back')}}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BTooltip, BTable, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    BTooltip, BTable, BCard, BButton, BRow, BCol, BFormInput, BCardHeader, BCardBody, BForm, ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    medicinesList: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      required,
      resourcesElementName: 'services',
      labelData: '.object.data',
      filter: '',
      tableColumns: []
    }
  },
  watch:{
    filter: function() {
      this.fetchElements()
    }
  },
  mounted() {
    this.tableColumns = [
        { key: 'cups_name', label: this.$t(this.resourcesElementName + ".object.medicine_name"), sortable: true },
        { key: 'cups', label: this.$t(this.resourcesElementName + ".object.cum_code"), sortable: true },
        { key: 'actions', label: this.$t(this.resourcesElementName + ".object.actions"), thStyle: 'width: 300px;' } 
      ]
    this.fetchElements()
  },
  methods: {
    get_select_button_id(key) {
      return 'select_button' + key
    },
    hide_modal() {
      this.$root.$emit('bv::hide::modal', 'modal-medicine')
    },
    fetchElements: function() {
      this.$root.$emit('fetch-medicines', this.filter)
    },
    select_medicine(item) {
      this.$root.$emit('save-medicine', item)
      this.$root.$emit('bv::hide::modal', 'modal-medicine')
    }
  }
}
</script>

<style>

.hidden-div{
    display: none;
  }
</style>
