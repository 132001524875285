import { render, staticRenderFns } from "./EightMedicalCertificationsCard.vue?vue&type=template&id=4544fa48"
import script from "./EightMedicalCertificationsCard.vue?vue&type=script&lang=js"
export * from "./EightMedicalCertificationsCard.vue?vue&type=script&lang=js"
import style0 from "./EightMedicalCertificationsCard.vue?vue&type=style&index=0&id=4544fa48&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports