<template>
  <div>
    <b-form @submit.prevent="save">
      <validation-observer
        ref="elementForm"
        #default="{ invalid }">
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="d-none d-sm-inline">{{
                $t(consultResourcesElementName + '.section.medicine')
              }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="add_medicine()">
                {{ $t('actions.add') }}
              </b-button>
            </b-row>
            <b-row>
              <b-table
                class="position-relative"
                :items="orderData.services"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                :empty-text="
                  $t(consultResourcesElementName + '.search.not_found')
                ">
                <!-- Column: actions -->
                <template #cell(actions)="data">
                  <b-col class="flex flex-row flex-wrap">
                    <b-button
                      :id="get_delete_button_id(data.item.id)"
                      style="margin: 1px"
                      variant="primary"
                      @click="delete_medicine(data.item.id)">
                      <feather-icon icon="DeleteIcon" />
                    </b-button>
                    <b-button
                      :id="get_edit_button_id(data.item.id)"
                      style="margin: 1px"
                      variant="primary"
                      @click="edit_medicine(data.item)">
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <!-- ? EDIT -->
                    <b-tooltip
                      :target="get_edit_button_id(data.item.id)"
                      :title="$t('actions.edit')"
                      triggers="hover"
                      :noninteractive="true"
                      fallback-placement="counterclockwise">
                    </b-tooltip>
                    <!-- ? DELETE -->
                    <b-tooltip
                      :target="get_delete_button_id(data.item.id)"
                      :title="$t('actions.delete')"
                      triggers="hover"
                      :noninteractive="true"
                      fallback-placement="counterclockwise">
                    </b-tooltip>
                  </b-col>
                </template>
              </b-table>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                md="12">
                <b-form-group label-for="service_observations">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(
                      consultResourcesElementName +
                        '.object.service_observations'
                    )
                  }}</span
                  ><span class="text-danger">*</span>

                  <validation-provider
                    #default="{ errors }"
                    :name="
                      $t(
                        consultResourcesElementName +
                          '.object.service_observations'
                      )
                    "
                    rules="required">
                    <b-form-textarea
                      id="observations"
                      v-model="orderData.observations"
                      maxlength="250"
                      max="250" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                id="cancel-button"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                variant="primary"
                @click="hide_modal()">
                {{ $t('actions.back') }}
              </b-button>
              <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="invalid">
                {{ $t('actions.save') }}
              </b-button>
            </b-row>
          </b-card-body>
        </b-card>
      </validation-observer>
    </b-form>
    <div>
      <b-modal
        id="modal-medicine"
        size="lg"
        hide-footer
        :title="$t(consultResourcesElementName + '.object.medicine')">
        <template>
          <medicine
            v-if="medicineData"
            :medicine-data="medicineData"
            :medicines-list="internalMedicinesList" />
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BModal,
  BTooltip,
  BTable,
  BFormTextarea,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Medicine from './Medicine.vue'

export default {
  components: {
    BModal,
    BTooltip,
    Medicine,
    BTable,
    BFormTextarea,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    orderData: {
      type: Object,
      required: true,
    },
    destinationOrdersList: {
      type: Array,
      required: true,
    },
    medicinesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      medicineResourcesElementName: 'services',
      tableColumns: [],
      medicineData: {},
      internalMedicinesList: [],
    }
  },
  watch: {},
  beforeDestroy() {
    this.$root.$off('save-medicine')
  },
  mounted() {
    this.tableColumns = [
      {
        key: 'cups',
        label: this.$t(`${this.medicineResourcesElementName}.object.cum_code`),
        sortable: true,
      },
      {
        key: 'cups_name',
        label: this.$t(`${this.medicineResourcesElementName}.object.medicine`),
        sortable: true,
      },
      {
        key: 'concentration',
        label: this.$t(
          `${this.medicineResourcesElementName}.object.concentration`
        ),
        sortable: true,
        formatter: (value, key, item) =>
          `${item.quantity} ${item.units_of_measure.value}`,
      },
      {
        key: 'dosage_form.value',
        label: this.$t(
          `${this.medicineResourcesElementName}.object.dosage_form`
        ),
        sortable: true,
      },
      {
        key: 'administration_route.value',
        label: this.$t(
          `${this.medicineResourcesElementName}.object.administration_route`
        ),
        sortable: true,
      },
      {
        key: 'dosage',
        label: this.$t(`${this.medicineResourcesElementName}.object.quantity`),
        sortable: true,
      },
      {
        key: 'frequency',
        label: this.$t(`${this.medicineResourcesElementName}.object.dosage`),
        sortable: true,
      },
      {
        key: 'treatment_time',
        label: this.$t(
          `${this.medicineResourcesElementName}.object.treatment_time`
        ),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.consultResourcesElementName}.object.actions`),
      },
    ]
    this.internalMedicinesList = this.medicinesList
    console.log(this.internalMedicinesList)
    const _self = this
    this.$root.$on('save-medicine', (medicineData) => {
      // if (medicineData.medicine_group.key != 'N/A') {
      //   _self.internalMedicinesList = _self.medicinesList.filter(element => element.medicine_group.id == medicineData.medicine_group.id || element.medicine_group.key == 'N/A')
      // } else {
      //   _self.internalMedicinesList = _self.medicinesList
      // }
      const index = _self.orderData.services.findIndex(
        (element) => element.id == medicineData.id
      )
      if (index < 0) {
        let canAdd = true
        if (_self.orderData.services.length > 0) {
          const serv = _self.orderData.services.find(
            (element) =>
              element.medicine_group.key === medicineData.medicine_group.key &&
              element.medicine_group.key !== 'N/A'
          )
          canAdd = !serv || medicineData.medicine_group.key === 'N/A'
        }
        if (canAdd) {
          _self.orderData.services.push(medicineData)
          _self.elementData.services.push(medicineData)
        } else {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t('consult.action_results.add_medicine.title'),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t('consult.action_results.add_medicine.failure'),
            },
          })
        }
      }
    })
  },
  methods: {
    get_edit_button_id(key) {
      return `edit_medicine_button${key}`
    },
    get_delete_button_id(key) {
      return `delete_medicine_button${key}`
    },
    hide_modal() {
      this.$root.$emit('bv::hide::modal', 'modal-medicines')
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then((success) => {
        if (success) {
          _self.$root.$emit('save-order', _self.orderData)
          _self.$root.$emit('bv::hide::modal', 'modal-medicines')
        }
      })
    },
    add_medicine() {
      this.medicineData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        reference_unit: { value: '' },
        dosage_form: { value: '' },
        administration_route: { value: '' },
      }
      this.$root.$emit('bv::show::modal', 'modal-medicine')
    },
    edit_medicine(item) {
      this.medicineData = item
      this.$root.$emit('bv::show::modal', 'modal-medicine')
    },
    delete_medicine(id) {
      this.orderData.services.splice(
        this.orderData.services.findIndex((element) => element.id == id),
        1
      )
      const tmp = this.orderData.services.filter(
        (element) => element.medicine_group.key != 'N/A'
      )
      if (tmp.length < 1) {
        this.internalMedicinesList = this.medicinesList
      }
      this.elementData.services.splice(
        this.elementData.services.findIndex((element) => element.id == id),
        1
      )
    },
  },
}
</script>
<style>
.left_title {
  text-decoration: underline;
  font-weight: bolder !important;
}

.hidden-div {
  display: none;
}
</style>
