var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.section.accident_characteristics')))])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('date-picker',{attrs:{"element-required":!_vm.disabled,"element-field":"accident_date","root-element-data":_vm.elementData,"element-label":_vm.$t(_vm.consultResourcesElementName + '.object.accident_date'),"disabled":_vm.disabled}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('radio-select-picker',{attrs:{"element-required":!_vm.disabled,"element-field":"job_held_id","root-element-data":_vm.elementData,"element-label":_vm.$t(_vm.consultResourcesElementName + '.object.job_held'),"element-selection-list":_vm.jobHeldList,"inline":false,"disabled":_vm.disabled}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"consult_motivation"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.consult_motivation')))]),(!_vm.disabled)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.consultResourcesElementName + '.object.consult_motivation'),"rules":_vm.disabled ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"consult_motivation","maxlength":"6000","max":"6000","disabled":_vm.disabled},model:{value:(_vm.elementData.consult_motivation),callback:function ($$v) {_vm.$set(_vm.elementData, "consult_motivation", $$v)},expression:"elementData.consult_motivation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"current_disease"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.current_disease')))]),(!_vm.disabled)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.consultResourcesElementName + '.object.current_disease'),"rules":_vm.disabled ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"current_disease","maxlength":"6000","max":"6000","disabled":_vm.disabled},model:{value:(_vm.elementData.current_disease),callback:function ($$v) {_vm.$set(_vm.elementData, "current_disease", $$v)},expression:"elementData.current_disease"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"background"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.background')))]),(!_vm.disabled)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.consultResourcesElementName + '.object.background'),"rules":_vm.disabled ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"background","maxlength":"6000","max":"6000","disabled":_vm.disabled},model:{value:(_vm.elementData.background),callback:function ($$v) {_vm.$set(_vm.elementData, "background", $$v)},expression:"elementData.background"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"system_review"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.object.system_review')))]),(!_vm.disabled)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.consultResourcesElementName + '.object.system_review'),"rules":_vm.disabled ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"system_review","maxlength":"6000","max":"6000","disabled":_vm.disabled},model:{value:(_vm.elementData.system_review),callback:function ($$v) {_vm.$set(_vm.elementData, "system_review", $$v)},expression:"elementData.system_review"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t(_vm.consultResourcesElementName + '.section.medical_exams')))])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"12"}},[_c('Lab-tests-list',{attrs:{"case_id":_vm.elementData.id,"title":_vm.$t('labtests.section.employee'),"type":"PATIENT"}})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }